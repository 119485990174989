import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  methods: {
    loadPaperList2 (callback) {
      if (!this.term) return
      this.showLoading = true
      //
      this.$http.post(`${this.httpRoot}/paper/search/esearchurl`, this.getParam2(), authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.url) {
          this.loadPmids(res.url, (eresult) => {
            this.showLoading = false
            // 更新一批idlist
            let esearchresult = eresult.esearchresult
            this.translation = this.getTranslation(esearchresult.translationstack || [])
            this.sum = Number(esearchresult.count)
            this.retmax = Number(esearchresult.retmax)
            this.retstart = Number(esearchresult.retstart)
            this.idlist[this.retstart] = esearchresult.idlist || []
            // this.webenv = esearchresult.webenv || ''
            // this.querykey = esearchresult.querykey || '1'
            // 从 idlist 中取当前页 20 个（注意不一定是第一页）
            let pmids = this.getPmids(this.page, this.pageSize)
            // 加载 paperList 关闭 loading 状态, 并更新pageIndex
            this.loadPapers(pmids)
            if (typeof callback === 'function') {
              callback()
            }

            if (Number(esearchresult.count) === 0 || !esearchresult.idlist.length) {
              this.paperList = []
              this.showResultNull = true
              if (esearchresult.warninglist && esearchresult.warninglist.outputmessages) {
                this.nullHint = esearchresult.warninglist.outputmessages.toString()
              } else {
                this.nullHint = ''
              }
            } else {
              this.showResultNull = false
            }
            // 更新检索历史
            eresult.term = res.term
            this.updateEsearchResult(eresult)
          })
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
      // loadYearCounts
      if (this.page === 0 && !this.years) {
        this.loadYearCounts()
      }
    },
    updateEsearchResult (result) {
      this.$http.post(`${this.httpRoot}/paper/search/esearchresult/add`, result, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          //
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadPmids (url, callback) {
      // url = 'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmax=1000&retmode=json&retstart=0&term=%28dna%29&sort=relevance'
      let xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.onload = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let res = ''
          try {
            res = JSON.parse(xhr.responseText)
          } catch (e) {
            console.log(e)
            res = ''
          }
          if (typeof callback === 'function') {
            callback(res)
          }
        } else {
          console.log('error', xhr)
          this.showLoading = false
          this.nullHint = xhr.responseText
          this.paperList = []
          this.showResultNull = true
        }
      }
      xhr.onerror = function (e) {
        console.error(xhr.statusText)
        this.showLoading = false
        this.nullHint = xhr.responseText
        this.paperList = []
        this.showResultNull = true
      }
      xhr.send(null)
    },
    getTranslation (translationstack) {
      let translation = []
      translationstack.forEach((item, index) => {
        let term = item.term || ''
        if (term) {
          let termEndIdx = term.indexOf('[')
          if (termEndIdx > 0) {
            term = term.slice(0, termEndIdx)
          }
          if (term.startsWith('"')) {
            term = term.slice(1)
          }
          if (term.endsWith('"')) {
            term = term.slice(0, term.length - 1)
          }
          if (term.indexOf(' ') > 0) {
            term = term.replace(' ', '-')
          }
          if (!translation.includes(term)) {
            translation.push(term)
          }
        }
      })
      return translation.join('/')
    }
  }
}
