export default {
  methods: {
    loadPaperListFromCache (callback) {
      let term = this.$store.state.paper.term
      let sum = this.$store.state.paper.sum
      let paperList = this.$store.state.paper.paperList
      let page = this.$store.state.paper.page
      let yearCounts = this.$store.state.paper.yearCounts
      let selectedList = this.$store.state.paper.selectedList
      let idlist = this.$store.state.paper.idlist
      let showPage = this.$store.state.paper.showPage
      if (term && paperList && paperList.length && sum) {
        this.term = term
        this.sum = sum
        this.paperList = paperList
        this.page = page
        this.yearCounts = yearCounts
        this.pageIndex = this.page
        this.selectedList = selectedList
        this.idlist = idlist
        this.showPage = showPage
        if (typeof callback === 'function') {
          callback()
        }
      }
    },
    cachePaperList (term, sum, paperList, page, yearCounts, selectedList, idlist, showPage) {
      this.$store.dispatch('updatePaperCache', {
        term: term,
        sum: sum,
        paperList: JSON.parse(JSON.stringify(paperList)),
        page: page,
        yearCounts: JSON.parse(JSON.stringify(yearCounts)),
        selectedList: JSON.parse(JSON.stringify(selectedList)),
        idlist: JSON.parse(JSON.stringify(idlist)),
        showPage: showPage
      })
    }
  }
}
