<template>
  <div class="cu-bar-wrapper">
    <svg :viewBox="viwBox" :width="width" :height="height" @mouseleave="handleMouseLeaveView" @mousemove="handleMouseMove"
     xmlns="http://www.w3.org/2000/svg">
      <g v-if="title">
        <text class="cu-bar-title" :x="width/2" :y="titleY" :style="titleStyle" text-anchor="middle">{{title}}</text>
      </g>
      <g class="cu-grid" v-if="showGridLine">
        <path class="cu-grid-line" v-for="(item, index) in ticksY" :key="index" :d="`M ${grid.ltx} ${item.my} L ${grid.rtx} ${item.ly}`"></path>
      </g>
      <g class="cu-bar-group" :class="{selected: barStatus[item.name]}" v-for="(item, index) in barGroup" :key="index" @click.stop="handleBarClick(item)">
        <rect class="cu-bar-bg" :x="item.x" :y="item.gy" :width="item.width" :height="item.gHeight"></rect>
        <rect v-if="barBackground==='auto'" class="cu-bar-auto" :x="item.x" :y="item.y" :width="item.width" :height="item.height"
          :fill="barColors[index%barColors.length]"
          @mouseenter="handleMouseEnterBar(index, $event)"
          @mouseleave="handleMouseLeaveBar(index, $event)"></rect>
        <rect v-else class="cu-bar" :x="item.x" :y="item.y" :width="item.width" :height="item.height"></rect>
      </g>
      <g class="cu-axis-x" v-if="showXAxis">
        <path class="cu-axis" :d="`M ${grid.lbx} ${grid.lby} L ${grid.rbx} ${grid.rby}`"></path>
      </g>
      <g class="cu-axis-y" v-if="showYAxis">
        <path class="cu-axis" :d="`M ${grid.ltx} ${grid.lty} L ${grid.lbx} ${grid.lby}`"></path>
      </g>
      <g class="cu-ticks-x" v-if="showXAxisTick">
        <path class="cu-tick" v-for="(item, index) in ticks" :key="index" :d="`M ${item.mx} ${item.my} L ${item.lx} ${item.ly}`"></path>
      </g>
      <g class="cu-ticks-y" v-if="showYAxisTick">
        <path class="cu-tick" v-for="(item, index) in ticksY" :key="index" :d="`M ${item.mx} ${item.my} L ${item.lx} ${item.ly}`"></path>
      </g>
      <g class="cu-labels-x" v-if="showXLabel">
        <text class="cu-label-axis" v-for="(item, index) in labelX" :key="index" :text-anchor="item.textAnchor" :x="item.x" :y="item.y" :transform="item.transform">{{item.label}}</text>
      </g>
      <g class="cu-labels-y" v-if="showYLabel">
        <text class="cu-label-axis" v-for="(item, index) in ticksY" :key="index" text-anchor="end" :x="item.lx-3" :y="item.ly+4">{{item.label}}</text>
      </g>
    </svg>
    <div v-if="hintPos==='auto'" class="hint-auto" :style="{left: hintLeftAuto+'px', top: hintTopAuto+'px', display: showHintAuto?'block':'none'}"
      @mouseenter="handleMouseEnterHintTop">
      <span>{{currentName}}:</span>
      <span>{{currentValue}}</span>
    </div>
    <div v-if="hintPos==='bottom'" class="hint-bottom" :style="{left: hintLeft+'px', display: showHintBottom?'block':'none'}">
      <span>{{currentName}}:</span>
      <span>{{currentValue}}</span>
    </div>
    <div v-if="hintPos==='top'" class="hint-top" :style="{left: hintLeft+'px', top: hintTop+'px', display: showHintTop?'block':'none'}"
      @mouseenter="handleMouseEnterHintTop"
      @mouseleave="handleMouseLeaveHintTop">
      <span>{{currentName}}:</span>
      <span>{{currentValue}}</span>
    </div>
    <svg class="timeline-filter-gradient" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="timeline-filter-selected-gradient" gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="0" y2="100%">
          <stop offset="0" stop-color="#0d71ba"></stop>
          <stop offset="0.7" stop-color="#1fb597"></stop>
          <stop offset="1" stop-color="#1eb194"></stop>
        </linearGradient>
        <linearGradient id="timeline-filter-hovered-gradient" gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="0" y2="100%">
          <stop offset="0" stop-color="#0c62a5"></stop>
          <stop offset="0.7" stop-color="#1c9a7f"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>
<script>
import barmixin from './barmixin'
export default {
  mixins: [barmixin],
  computed: {
    barGroup () {
      if (!this.data) return []
      let barList = []
      // 柱条最大值
      let barMaxVal = 0
      // 柱条个数
      let barNum = this.data.length || 1
      // 柱条最大值
      this.data.forEach(item => {
        if (item.value > barMaxVal) barMaxVal = item.value
      })
      barMaxVal = this.getBarMaxValue(barMaxVal)
      // chart grid 实际区域高宽
      let gridWidth = this.width - this.marginRight - this.marginLeft
      let gridHeight = this.height - this.marginBottom - this.marginTop
      if (gridWidth <= 0 || gridHeight <= 0) {
        throw new Error('margin is bigger than gird\'s width or height')
      }
      // 柱条宽度，柱条间隙总宽度 = barNum * barInterval
      let barWidth = (gridWidth - barNum * this.barInterval) / barNum
      this.data.forEach((item, index) => {
        let bar = {
          name: item.name,
          value: item.value,
          x: (index + 0.5) * this.barInterval + index * barWidth + this.marginLeft,
          gy: this.marginTop,
          width: barWidth,
          height: (item.value / barMaxVal) * gridHeight,
          gHeight: gridHeight,
          selected: true
        }
        barList.push(bar)
      })
      // bar.y 平移距离为 gridHeight - bar.height，即中空距离
      barList.forEach(bar => {
        bar.y = bar.gy + (gridHeight - bar.height)
      })
      return barList
    },
    ticks () {
      let tickList = []
      let barNum = this.data.length || 1
      let gridWidth = this.width - this.marginRight - this.marginLeft
      let barWidth = (gridWidth - barNum * this.barInterval) / barNum
      let gdBottom = this.height - this.marginBottom
      if (gridWidth <= 0) {
        throw new Error('margin is bigger than gird\'s width')
      }
      for (let i = 0; i < this.data.length + 1; i++) {
        tickList.push({
          mx: (barWidth + this.barInterval) * i + this.marginLeft,
          my: gdBottom,
          lx: (barWidth + this.barInterval) * i + this.marginLeft,
          ly: gdBottom + 5
        })
      }
      return tickList
    },
    ticksY () {
      let tickList = []
      // 柱条最大值
      let barMaxVal = 0
      this.data.forEach(item => {
        if (item.value > barMaxVal) barMaxVal = item.value
      })
      barMaxVal = this.getBarMaxValue(barMaxVal)
      let intervalValue = barMaxVal / 5
      let gridHeight = this.height - this.marginBottom - this.marginTop
      let intervalHeight = gridHeight / 5
      if (gridHeight <= 0) {
        throw new Error('margin is bigger than gird\'s height')
      }
      if (this.data.length) {
        for (let i = 0; i < 6; i++) {
          tickList.push({
            mx: this.marginLeft,
            my: i * intervalHeight + this.marginTop,
            lx: this.marginLeft - 5,
            ly: i * intervalHeight + this.marginTop,
            label: parseInt((5 - i) * intervalValue * 10) / 10
          })
        }
      }
      return tickList
    },
    labelX () {
      let labels = []
      let barNum = this.data.length || 1
      // chart grid 实际区域高宽
      let gridWidth = this.width - this.marginRight - this.marginLeft
      // 柱条宽度，柱条间隙总宽度 = barNum * barInterval
      let barWidth = (gridWidth - barNum * this.barInterval) / barNum
      if (gridWidth <= 0) {
        throw new Error('margin is bigger than gird\'s width')
      }
      this.data.forEach((item, index) => {
        let label = {
          x: (index + 0.5) * this.barInterval + (index + 0.5) * barWidth + this.marginLeft,
          y: this.height - this.marginBottom + 15,
          label: item.name,
          textAnchor: 'middle',
          transform: 'translate(0,0)'
        }
        labels.push(label)
      })
      // 设置旋转以及旋转轴点
      if (this.xlabelRotate) {
        labels.forEach(label => {
          label.textAnchor = 'end'
          label.x += 5
          label.y -= 5
          label.transform = `rotate(-45, ${label.x}, ${label.y})`
        })
      }
      return labels
    }
  },
  methods: {
    updateBarInterval () {
      if (this.options.bar) {
        let gap = this.options.bar.interval
        let barNum = this.data.length || 1
        if (gap === 'one-five') {
          this.barInterval = (this.height / barNum) / 5
        } else if (gap === 'auto') {
          this.barInterval = (this.height / barNum) / 4
        } else if (gap === 'one-third') {
          this.barInterval = (this.height / barNum) / 3
        } else if (gap === 'half') {
          this.barInterval = (this.height / barNum) / 2
        } else if (gap !== 'none') {
          this.barInterval = Number(this.options.bar.interval)
        }
      }
    },
    handleMouseMove (e) {
      let x = e.offsetX
      let y = e.offsetY
      if (this.hintPos === 'auto') {
        let hint = false
        for (let i = this.barGroup.length - 1; i >= 0; i--) {
          let item = this.barGroup[i]
          if (x >= item.x && x <= item.x + item.width &&
              y >= item.y && y <= this.height - this.marginBottom) {
            this.currentName = item.name
            this.currentValue = item.value
            this.showHint()
            let charWidth = 15 * this.currentName.length + 7 * (this.currentValue.length + 1) + 16
            let charHeight = 16 + 10
            let $hintAuto = this.$el.querySelector('.hint-auto')
            let hintWidth = ($hintAuto && $hintAuto.offsetWidth) || charWidth
            let hintHeight = ($hintAuto && $hintAuto.offsetHeight) || charHeight
            if (y + this.hintPosDis > this.height - this.marginBottom && x + this.hintPosDis > this.width - this.marginRight) {
              // 超过右下，移到左上角
              this.moveHint(this.hintLeftAuto, this.hintTopAuto, x - this.hintPosDis - hintWidth, y - this.hintPosDis - hintHeight)
            } else if (y + this.hintPosDis > this.height - this.marginBottom) {
              // 超过下边，移到右上角
              this.moveHint(this.hintLeftAuto, this.hintTopAuto, x + this.hintPosDis, y - this.hintPosDis - hintHeight)
            } else if (x + this.hintPosDis > this.width - this.marginRight) {
              // 超过右边，移到左下角
              this.moveHint(this.hintLeftAuto, this.hintTopAuto, x - this.hintPosDis - hintWidth, y + this.hintPosDis)
            } else {
              this.moveHint(this.hintLeftAuto, this.hintTopAuto, x + this.hintPosDis, y + this.hintPosDis)
            }
            hint = true
            break
          }
        }
        if (!hint) {
          this.hiddenHint()
        }
      } else {
        // hintPos === 'top' | 'bottom'
        if (x <= this.marginLeft || x >= this.width - this.marginRight ||
            y <= this.marginTop || y >= this.height - this.marginBottom) {
          this.hiddenHint()
        } else {
          for (let i = this.barGroup.length - 1; i >= 0; i--) {
            let item = this.barGroup[i]
            if (x > item.x - this.barInterval / 2) {
              this.hintLeft = item.x + item.width / 2
              this.hintTop = item.y
              this.currentName = item.name
              this.currentValue = item.value
              this.showHint()
              break
            }
          }
        }
      }
    }
  }
}
</script>
<style>
.cu-bar-wrapper .hint-bottom {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(33,33,33,0.9);
  color: #fff;
  padding: 0.3rem 0.5rem;
  font-size: inherit;
  white-space: nowrap;
  text-align: center;
  border-radius: 2px;
  transform: translateX(-50%);
  display: none;
  top: 100%;
  transition: left ease-in-out 0.2s;
}
.cu-bar-wrapper .hint-bottom:before {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  border-top: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-bottom: 0.4rem solid rgba(33,33,33,0.9);
  border-left: 0.4rem solid transparent;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -95%);
}
.cu-bar-wrapper .hint-top {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(33,33,33,0.8);
  color: #fff;
  padding: 0.4rem 0.6rem;
  font-size: inherit;
  white-space: nowrap;
  text-align: center;
  border-radius: 2px;
  transform: translate(-50%, -120%);
  display: none;
  top: 0;
  transition: left ease-in-out 0.2s;
}
.cu-bar-wrapper .hint-top:before {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  border-top: 0.4rem solid rgba(33,33,33,0.8);
  border-right: 0.4rem solid transparent;
  border-bottom: 0.4rem solid transparent;
  border-left: 0.4rem solid transparent;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 95%);
}
</style>
