export default {
  methods: {
    getIdlistOneSize () {
      if (this.idlist[0] && this.idlist[0].length) {
        return this.idlist[0].length
      }
      return 1000
    },
    getRetstart (pageIndex, pageSize) {
      let start = pageIndex * pageSize
      let end = (pageIndex + 1) * pageSize
      let idlistonesize = this.getIdlistOneSize()
      let newRetstart = idlistonesize
      let i = 1
      while (i < 100) {
        let retstart = i * idlistonesize
        let retend = (i + 1) * idlistonesize
        if (start >= retstart && end <= retend && !this.idlist[retstart]) {
          newRetstart = retstart
          break
        }
        i += 1
      }
      return newRetstart
    },
    getPmids (pageIndex, pageSize) {
      // [start, end), 不考虑 idlist 段落越界的情况, 假设一页20个全在某一个段内
      let start = pageIndex * pageSize
      let end = (pageIndex + 1) * pageSize
      let pmids = []
      Object.keys(this.idlist).forEach(prop => {
        let retstart = Number(prop)
        let retmax = this.idlist[prop].length
        if (start >= retstart && end <= (retstart + this.ceilRetmax(retmax, pageSize))) {
          pmids = this.idlist[prop].slice(start - retstart, end - retstart)
        }
      })
      return pmids
    },
    ceilRetmax (val, pageSize) {
      return Math.ceil(val / pageSize) * pageSize
    }
  }
}
