<template>
  <div>
    <div class="segment-filter">
      <div class="title">可用全文</div>
      <div class="check-item" v-for="(item, index) in simsearch" :key="index">
        <div class="au-checkbox">
          <input type="checkbox" :id="item.value" :value="item.value" v-model="simsearchSelected">
          <label class="check-box" :for="item.value"></label>
        </div>
        <div class="label">{{item.title}}</div>
      </div>
    </div>
    <!-- <div class="segment-filter">
      <div class="title">文章属性</div>
      <div class="check-item" v-for="(item, index) in articleattr" :key="index">
        <div class="au-checkbox">
          <input type="checkbox" :id="item.value" :value="item.value" v-model="articleattrSelected">
          <label class="check-box" :for="item.value"></label>
        </div>
        <div class="label">{{item.title}}</div>
      </div>
    </div> -->
    <div class="segment-filter">
      <div class="title">论著类型</div>
      <div class="check-item" v-for="(item, index) in pubt" :key="index">
        <div class="au-checkbox">
          <input type="checkbox" :id="item.value" :value="item.value" v-model="pubtSelected">
          <label class="check-box" :for="item.value"></label>
        </div>
        <div class="label">{{item.title}}</div>
      </div>
    </div>
    <div class="segment-filter">
      <div class="title">其它</div>
      <div class="check-item" v-for="(item, index) in species" :key="index">
        <div class="au-checkbox">
          <input type="checkbox" :id="item.value" :value="item.value" v-model="speciesSelected">
          <label class="check-box" :for="item.value"></label>
        </div>
        <div class="label">{{item.title}}</div>
      </div>
    </div>
    <!-- <div class="segment-filter">
      <div class="title">发表日期</div>
      <div class="check-item" v-for="(item, index) in ds1" :key="index">
        <div class="au-radio">
          <input type="radio" :id="item.value" :value="item.value" v-model="ds1Selected">
          <label class="radio-box" :for="item.value"></label>
        </div>
        <div class="label">{{item.title}}</div>
      </div>
    </div> -->
    <div class="reset"><button @click.stop="clearFilter">清空过滤条件</button></div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      simsearch: [
        { value: 'fha', title: '摘要全文' },
        { value: 'ffrft', title: '免费全文' },
        { value: 'fft', title: '全文链接' }
      ],
      articleattr: [
        { value: 'data', title: '相关数据' }
      ],
      pubt: [
        { value: 'review', title: '综述' },
        { value: 'clinicaltrial', title: '临床试验' },
        { value: 'systematicreviews', title: '系统评价' },
        { value: 'randomizedcontrolledtrial', title: '随机对照' },
        { value: 'booksdocs', title: '书籍文档' },
        { value: 'metaanalysis', title: 'Meta分析' },
        { value: 'casereport', title: '病例报告' }
      ],
      species: [
        { value: 'humans', title: '人' },
        { value: 'animals', title: '动物' }
      ],
      ds1: [
        { value: 'y_1', title: '1年' },
        { value: 'y_5', title: '5年' },
        { value: 'y_10', title: '10年' }
      ],
      years: '2016-2019',
      //
      simsearchSelected: [],
      articleattrSelected: [],
      pubtSelected: [],
      speciesSelected: [],
      ds1Selected: '',
      clearTrigger: false
    }
  },
  watch: {
    simsearchSelected (val) {
      if (this.clearTrigger && !val.length) {
        return
      } else if (val.length) {
        this.clearTrigger = false
      }
      this.$emit('on-simsearch', val)
    },
    articleattrSelected (val) {
      if (this.clearTrigger && !val.length) {
        return
      } else if (val.length) {
        this.clearTrigger = false
      }
      this.$emit('on-articleattr', val)
    },
    pubtSelected (val) {
      if (this.clearTrigger && !val.length) {
        return
      } else if (val.length) {
        this.clearTrigger = false
      }
      this.$emit('on-pubt', val)
    },
    speciesSelected (val) {
      if (this.clearTrigger && !val.length) {
        return
      } else if (val.length) {
        this.clearTrigger = false
      }
      this.$emit('on-species', val)
    },
    ds1Selected (val) {
      if (this.clearTrigger && !val.length) {
        return
      } else if (val.length) {
        this.clearTrigger = false
      }
      this.$emit('on-ds1', val)
    }
  },
  methods: {
    clearFilter () {
      if (this.simsearchSelected.length || this.articleattrSelected.length ||
          this.pubtSelected.length || this.ds1Selected.length) {
        // 标记clearTrigger 防止数据监听触发事件
        this.clearTrigger = true
        this.simsearchSelected = []
        this.articleattrSelected = []
        this.pubtSelected = []
        this.ds1Selected = ''
        this.$emit('on-filter-clear')
      } else {
        this.$emit('on-years-reset')
      }
    }
  }
}
</script>
<style lang="less">
.segment-filter {
  .title {
    height: 2rem;
    line-height: 2rem;
    color: #666;
  }
  .check-item {
    height: 1.875rem;
    line-height: 1.875rem;
    display: flex;
  }
}
.reset {
  margin-top: 10px;
  button {
    cursor: pointer;
    color: #212121;
    background-color: transparent;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    border: 1px solid #aeb0b5;
    transition: background-color 0.3s, border-color 0.3s,color 0.3s;
    padding: 0.3rem 0.8rem;
    height: 2rem;
    &:active {
      border-color: #4688ff;
    }
  }
}
</style>
