<template>
  <div class="paper-static">
    <bar :width="160" :height="80" :data="barData" :selectedData="selectedBarData" :options="options" @on-bar-click="onBarClick"></bar>
    <div class="slider" v-show="showSlider">
      <div class="start-achor">{{ startName }}</div>
      <div class="end-achor">{{ endName }}</div>
    </div>
    <div class="select">
      <!-- <span class="label">起止年:</span> -->
      <button @click.stop="handleReset">重置</button>
      <input class="start" v-model="startYear" @change="handleStartYearChange">
      <span>-</span>
      <input class="end" v-model="endYear" @change="handleEndYearChange">
      <button @click.stop="handleSearch">查询</button>
    </div>
    <div class="select-years">
      <button @click.stop="select1">近1年</button>
      <button @click.stop="select3">近3年</button>
      <button @click.stop="select5">近5年</button>
    </div>
  </div>
</template>
<script>
import Bar from '../common/charts/bar'
export default {
  components: {Bar},
  props: {
    datas: Object
  },
  data () {
    return {
      originalData: [],
      barData: [],
      selectedBarData: [],
      showSlider: true,
      startName: '',
      endName: '',
      startYear: '',
      endYear: '',
      options: {
        margin: {
          top: 10,
          right: 5,
          bottom: 5,
          left: 35
        },
        bar: {
          interval: 'none',  // 'auto'
          background: 'selected'  // 'auto', 'selected'
        },
        hint: {
          pos: 'bottom'  // 'auto'
        },
        x: {
          label: false,
          labelRotate: false,
          axis: false,
          tick: false
        },
        y: {
          label: true,
          axis: false,
          tick: false
        },
        grid: {
          line: true
        }
      }
    }
  },
  watch: {
    datas (val) {
      if (val) {
        this.initData()
      }
    },
    startYear (val) {
      if (isNaN(Number(val))) {
        this.startYear = this.startName
      }
    },
    endYear (val) {
      if (isNaN(Number(val))) {
        this.endYear = this.endName
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.barData = this.mapObj2Array(this.datas)
      this.originalData = JSON.parse(JSON.stringify(this.barData))
      if (this.barData.length) {
        this.startName = this.barData[0].name
        this.endName = this.barData[this.barData.length - 1].name
        this.startYear = this.startName
        this.endYear = this.endName
        this.showSlider = true
      } else {
        this.startName = ''
        this.endName = ''
        this.startYear = ''
        this.endYear = ''
        this.showSlider = false
      }
      this.selectedBarData = []
    },
    mapObj2Array (obj) {
      let array = []
      Object.keys(obj || {}).forEach(prop => {
        array.push({
          name: prop,
          value: obj[prop]
        })
      })
      return array
    },
    onBarClick (data) {
      this.$emit('on-years-change', data.name, data.name)
    },
    handleReset () {
      this.startYear = this.startName
      this.endYear = this.endName
      this.barData = JSON.parse(JSON.stringify(this.originalData))
      this.selectedBarData = []
      this.$emit('on-years-reset')
    },
    handleSearch () {
      if (this.startYear && this.endYear) {
        this.$emit('on-years-change', this.startYear, this.endYear)
      }
    },
    select1 () {
      this.startYear = this.endYear = String(new Date().getFullYear())
      if (this.startYear && this.endYear) {
        this.$emit('on-years-change', this.startYear, this.endYear)
      }
    },
    select3 () {
      this.endYear = String(new Date().getFullYear())
      this.startYear = String(Number(this.endYear) - 2)
      if (this.originalData.length > 10) {
        this.barData = this.originalData.slice(this.originalData.length - 10)
      }
      if (this.startYear && this.endYear) {
        this.$emit('on-years-change', this.startYear, this.endYear)
      }
    },
    select5 () {
      this.endYear = String(new Date().getFullYear())
      this.startYear = String(Number(this.endYear) - 4)
      if (this.originalData.length > 15) {
        this.barData = this.originalData.slice(this.originalData.length - 15)
      }
      if (this.startYear && this.endYear) {
        this.$emit('on-years-change', this.startYear, this.endYear)
      }
    },
    handleStartYearChange (e) {
      let value = Number(e.target.value)
      if (value > Number(this.endName) || value < Number(this.startName)) {
        this.startYear = this.startName
      }
      this.filterBarData(true)
    },
    handleEndYearChange (e) {
      let value = Number(e.target.value)
      if (value > Number(this.endName) || value < Number(this.startName)) {
        this.endYear = this.endName
      }
      this.filterBarData(true)
    },
    filterBarData (inner) {
      let temp = []
      this.originalData.forEach(item => {
        if (Number(item.name) >= Number(this.startYear) && (Number(item.name) <= Number(this.endYear))) {
          temp.push(item)
        }
      })
      if (this.originalData.length > temp.length && inner) {
        this.barData = this.originalData.slice(this.originalData.length - temp.length)
      }
      this.selectedBarData = temp
    },
    filterYearRange (startYear, endYear) {
      setTimeout(() => {
        this.startYear = startYear
        this.endYear = endYear
        this.filterBarData()
      }, 50)
    }
  }
}
</script>
<style lang="less">
.paper-static {
  width: 160px;
  margin: 0.5rem 0;
  position: relative;
  .slider {
    position: relative;
    top: -9px;
    margin-left: 25px;
    height: 1px;
    background-color: #1ca186;
    &:before {
      content: '';
      border: 1px solid #1ca186;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      transform: translate(20%, -45%);
    }
    &:after {
      content: '';
      border: 1px solid #1ca186;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 0;
      right: 0;
      transform: translate(-15%, -45%);
    }
    &> .start-achor, .end-achor {
      font-size: 12px;
      position: absolute;
    }
    &> .start-achor {
      top: 0;
      left: 0;
      transform: translate(-20%, 30%);
    }
    &> .end-achor {
      top: 0;
      right: 0;
      transform: translate(20%, 30%);
    }
  }
  .select {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &>.label, &>.start, &>.end {
      font-size: 12px;
      height: 14px;
      line-height: 14px;
      display: inline-block;
    }
    &>.start, &>.end {
      width: 30px;
      outline: 0;
    }
    &> button {
      width: 32px;
      white-space: nowrap;
      font-size: 12px;
      padding: 1px 4px;
      padding-bottom: 2px;
      background: #eee;
      border: 0;
      outline: 0;
      cursor: pointer;
      &:hover {
        color: #107458;
        background-color: #f1f1f1;
      }
    }
  }
  .select-years {
    margin-top: 6px;
    margin-left: 2px;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &> button {
      cursor: pointer;
      color: #212121;
      background-color: transparent;
      display: inline-block;
      font-size: 12px;
      text-align: center;
      text-decoration: none;
      border: 1px solid #aeb0b5;
      transition: background-color 0.3s, border-color 0.3s,color 0.3s;
      padding: 2px 5px;
      outline: 0;
      &:active {
        border-color: #4688ff;
      }
    }
  }
}
</style>
