import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  data () {
    return {
      pageIndexMap: 0
    }
  },
  methods: {
    getRetstartTest () {
      // 每次的新idlist进行覆盖，没有取到新的pmids, 重新取一个批次
      return this.retstart + this.retmax // retmax 默认1000
    },
    getPmidsTest (pageIndex, pageSize) {
      // pageIndex 下标 0 起始
      // 从idlist 列表中取 [start, end)
      const mapPageIndex = pageIndex - this.pageIndexMap
      let start = mapPageIndex * pageSize
      let end = (mapPageIndex + 1) * pageSize
      let pmids = []
      if (this.idlist.length > start) {
        pmids = this.idlist.slice(start, end)
      }
      return pmids
    },
    handlePaginationChangeTest () {
      // page, pageSize 发生变化
      setTimeout(() => {
        this.pageIndexChange = 0
      }, 500)
      let pmids = this.getPmidsTest(this.page, this.pageSize)
      if (pmids.length) {
        // 加载 paperList 关闭 loading 状态, 并更新pageIndex
        this.loadPapers(pmids)
      } else {
        // 没取到pmids, 需要取下一批 idlist (前面取过的不需要取)
        // 根据 page 更新 retstart, 首页映射
        this.retstart = this.getRetstartTest()
        this.pageIndexMap = this.page
        this.loadPaperListTest()
      }
    },
    loadPaperListTest (callback) {
      if (!this.term) return
      this.showLoading = true

      this.$http.post(`${this.httpRoot}/paper/search/pmids/translation`, this.getParam2(), authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.result) {
          //
          this.translation = this.getTranslation(res.result.translationstack || [])
          // 更新一批idlist
          this.retmax = Number(res.result.retmax)
          this.retstart = Number(res.result.retstart)
          this.idlist = res.result.idlist || [] // 直接覆盖最新的idlist
          this.webenv = res.result.webenv || ''
          this.querykey = res.result.querykey || '1'
          this.sum = this.page * this.pageSize + (Number(res.result.count) - (this.retstart + this.retmax) + this.idlist.length)

          if (Number(res.result.count) === 0 || !res.result.idlist.length) {
            if (this.retstart + this.retmax >= Number(res.result.count)) {
              this.showLoading = false
              this.paperList = []
              this.showResultNull = true
              if (res.result.warninglist && res.result.warninglist.outputmessages) {
                this.nullHint = res.result.warninglist.outputmessages.toString()
              } else {
                this.nullHint = '所有查询为空'
              }
            } else {
              // 继续下一次请求
              this.retstart += this.retmax
              this.loadPaperListTest()
            }
          } else {
            this.showResultNull = false
            // 从 idlist 中取当前页 20 个（注意不一定是第一页）
            let pmids = this.getPmidsTest(this.page, this.pageSize)
            // 加载 paperList 关闭 loading 状态, 并更新pageIndex
            this.loadPapers(pmids)
            if (typeof callback === 'function') {
              callback()
            }
          }
        } else {
          this.showLoading = false
          this.nullHint = res.statusText
          this.paperList = []
          this.showResultNull = true
        }
      })
      .catch(err => {
        this.showLoading = false
        this.nullHint = ''
        this.paperList = []
        this.showResultNull = true
        catchExpired(err, this)
      })
      // loadYearCounts
      if (this.page === 0 && !this.years) {
        // this.loadYearCounts()
      }
    }
  }
}
