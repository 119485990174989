<template>
  <div>
    <div style="color: #0071bc;font-size: 1.125em;">
      <span v-if="paperCopy.read">
        <span class="tools-icon unread">&nbsp;</span>
      </span>
      <router-link target="_blank" class="paper-summary-title" :to="{name: 'paper', params: {id: paperCopy.id}}" v-highlight:[translation].t="paperCopy.a.t">
        <!-- {{paperCopy.a.t}} -->
      </router-link>
      <img class="copy-icon" src="../../assets/imgs/copy.png" alt="" @click.stop="onCopyTitle">
      <span class="altmetric" v-if="paperCopy.alt>0">
        <a target="_blank" :href="`https://www.altmetric.com/details/${paperCopy.altId}`">
          <span class="altmetric-icon" title="Altmetric评分"></span>
          <span class="altmetric-score" title="Altmetric评分">{{Math.floor(paperCopy.alt * 100) / 100}}</span>
        </a>
      </span>
      <template v-if="paperCopy.citedby">
        <span class="cite-num icon fa fa-quote-left" title="被引用">:</span>
        <span class="cite-num" title="被引用">{{paperCopy.citedby}}</span>
      </template>
      <router-link v-if="!paperCopy.citedby && paperCopy.pmcCites && paperCopy.pmcCites.length" target="_blank" class="paper-summary-title" :to="{name: 'paperSearch', query: {pmcCites: paperCopy.pmcCites.join(',')}}">
        <span class="cite-num fa fa-quote-left" title="被引用">:</span>
        <span class="cite-num" title="被引用">{{paperCopy.pmcCites.length}}</span>
      </router-link>
      <span class="ch-title" v-if="paperCopy.tCh">{{paperCopy.tCh}}</span>
      <span class="no-title" v-if="!paperCopy.a || !paperCopy.a.t">[No title available]</span>
    </div>
    <div style="color: #0E1E5C;" class="journal-info">
      <span class="authors" v-if="allAuthor === 'all' && paperCopy.authorIdx && paperCopy.authorIdx.length">
        <span class="name" v-for="(item, index) in paperCopy.authorIdx" :key="index">
          <span>{{ item.name }}</span>
          <span v-if="index<paperCopy.authorIdx.length-1">,</span>
        </span>
      </span>
      <span class="authors" v-if="allAuthor !== 'all' && paperCopy.authorIdx4 && paperCopy.authorIdx4.length">
        <span class="name" v-for="(item, index) in paperCopy.authorIdx4" :key="index">
          <span>{{ item.name }}</span>
          <span v-if="index<paperCopy.authorIdx4.length-1">,</span>
          <!-- <img v-if="item.idx===0" :class="{last:paperCopy.authorIdx.length===1}" src="../../assets/imgs/number1.png"/> -->
          <!-- <img v-if="item.idx===1" :class="{last:paperCopy.authorIdx.length===2}" src="../../assets/imgs/number2.png"/> -->
          <img v-if="item.idx===-2" :class="{last:paperCopy.authorIdx.length===3}" src="../../assets/imgs/number-2.png"/>
          <img class="last" v-if="item.idx===-1" src="../../assets/imgs/number-1.png"/>
        </span>
      </span>
      <span class="authors" v-if="!paperCopy.authorIdx.length">No authors listed</span>

    </div>
    <div style="color: #2e8540;" class="journal-wrap">
      <span class="yxy-score" v-if="paperCopy.score>0" title="银杏因子">
        <span>{{paperCopy.score.toFixed(1)}}</span>
      </span>
      <span class="quality" :class="[paperCopy.quality>=4?'green':paperCopy.quality>=3?'blue':paperCopy.quality>=2?'yellow':paperCopy.quality>=1?'red':'grey']">{{paperCopy.quality?paperCopy.quality+`区`:'未录'}}</span>
      <span class="if" :class="[paperCopy.impactFactor>=10?'red':paperCopy.impactFactor>=5?'yellow':paperCopy.impactFactor>=3?'blue':paperCopy.impactFactor>0?'green':'grey']">{{paperCopy.impactFactor?`IF:`+paperCopy.impactFactor:'Not Found'}}</span>
      <span class="journal" :class="[paperCopy.impactFactor>=10?'red':paperCopy.impactFactor>=5?'yellow':paperCopy.impactFactor>=3?'blue':paperCopy.impactFactor>0?'green':'grey']" @mouseenter="handleJournalMouseEnter($event)" @mouseleave="handleJournalMouseLeave()">{{paperCopy.journalTitle}}</span>
      <span class="other date" v-if="paperCopy.publicationDate">{{paperCopy.publicationDate}}<span v-if="paperCopy.volumeIssue">;</span><span v-else>.</span></span>
      <span class="other" v-if="paperCopy.volumeIssue">{{paperCopy.volumeIssue}}.</span>
      <span class="other" v-if="!paperCopy.volumeIssue && paperCopy.ids.pii">{{`pii: `+paperCopy.ids.pii}}.</span>
      <span class="other" v-if="paperCopy.ids.doi"><a target="_blank" :href="paperCopy.doiLink">{{`DOI:`+paperCopy.ids.doi}}</a></span>
      <span class="other"><a target="_blank" :href="paperCopy.pubmedLink">{{`PMID:`+paperCopy.id}}</a></span>
      <span class="other review" v-if="paperCopy.pubTypes && paperCopy.pubTypes.indexOf('Review')>=0">Review</span>
    </div>
    <div :class="['paper', format]" v-highlight:[translation]="paperCopy.abstractHTML"></div>
    <div class="no-abstract" v-if="!paperCopy.abstract">No abstract available</div>
    <div class="abstractch" v-if="chAbstract==='show' && paperCopy.abCh && !paperCopy.chErr">{{paperCopy.abCh}}<span class="ch-err" title="标记翻译不完整或不准确" @click.stop="sendTransError">翻译不完整</span></div>
    <div class="keywords" v-if="paperCopy.a.kw && (!format || format==='abstract')">
      <span class="label">Keywords：</span>
      <span v-highlight:[translation]="paperCopy.a.kw"></span>
    </div>
    <div class="full-text-link-panel">
      <span class="label">全文链接:</span>
      <div v-if="paperCopy.journalFullLink">
        <a target="_blank" class="journal" :href="paperCopy.journalFullLink" style="text-decoration:underline;">
          <img v-if="paperCopy.journalFullImage" :src="paperCopy.journalFullImage" alt="">
          <span v-else>期刊原文链接</span>
        </a>
      </div>
      <div v-if="paperCopy.pmcFullLink"><a target="_blank" class="pmc" :href="paperCopy.pmcFullLink"></a></div>
      <div v-if="paperCopy.sciHubLink"><a target="_blank" class="sci-hub" :href="paperCopy.sciHubLinkPre+scihubDomain+paperCopy.sciHubLinkPost" @click="onLinkClick">&nbsp;</a></div>
      <div v-if="paperCopy.fulltextLink"><a target="_blank" :class="[paperCopy.fulltextLink.toLowerCase().indexOf('pdf')>0?'pdf':'weblink']" :href="paperCopy.fulltextLink" @click="onLinkClick"></a></div>
      <div class="fulltext-help" @click="onFulltextHelp"></div>
    </div>
    <div class="tools-panel">
      <span v-if="paperCopy.status==='collect'" @click.stop="paperUnCollect">
        <span class="tools-icon fa fa-heart"></span>
        <span class="text">取消收藏</span>
      </span>
      <span v-else @click.stop="paperCollect">
        <span class="tools-icon fa fa-heart-o"></span>
        <span class="text">收藏</span>
      </span>
      <span v-if="paperCopy.read" @click.stop="paperUnRead">
        <span class="tools-icon read">&nbsp;</span>
        <span class="text">取消已读</span>
      </span>
      <span v-else @click.stop="paperRead">
        <span class="tools-icon read">&nbsp;</span>
        <span class="text">已读</span>
      </span>
      <span class="tools-icon fa fa-quote-left"></span>
      <span class="text" @click.stop="getCiteFormat">引用格式</span>
      <span class="tools-icon fa fa-share-square-o"></span>
      <span class="text" @click.stop="exportEndNote">EndNote格式</span>
      <span class="tools-icon fa fa-share-square-o"></span>
      <span class="text" @click.stop="exportNoteExpress">NoteExpress格式</span>
    </div>
    <citation-win :show="showCite" :left="winleft" :top="wintop" @on-hide="showCite=false" :citation="citation"></citation-win>
  </div>
</template>
<script>
import CitationWin from './citation-inner-win'
import transMethod from './mixins/translate'
import paperCopy from './mixins/paperCopy'
import Highlight from '../common/directives/highlight'
import { authenticate, catchExpired } from '../../utils/auth'
import { getStorageItem, updateStorageItem } from '../../utils/cache'
export default {
  components: {CitationWin},
  mixins: [transMethod, paperCopy],
  directives: {highlight: Highlight},
  props: {
    paper: {
      type: Object,
      default: () => { return {} }
    },
    format: String,
    chAbstract: String,
    allAuthor: String,
    term: String,
    translation: String
  },
  data () {
    return {
      citation: '',
      showCite: false,
      winleft: 0,
      wintop: 0,
      pdfUrl: '',
      webUrl: ''
    }
  },
  computed: {
    paperCopy () {
      let p = this.paper
      p.authorstr = this.authorstr()
      p.authorIdx = this.authorIdx()
      p.authorIdx4 = this.authorIdx4()
      p.authorafs = this.authorafs()
      p.volumeIssue = this.volumeIssue()
      p.publicationDate = this.publicationDate()
      p.journalTitle = this.journalTitle()
      p.abstract = this.abstract()
      p.abstractHTML = this.abstractHTML()
      p.pubmedLink = this.pubmedLink()
      p.doiLink = this.doiLink()
      p.journalFullLink = this.journalFullLink()
      p.pmcFullLink = this.pmcFullLink()
      p.sciHubLink = this.sciHubLink()
      p.sciHubLinkPre = this.sciHubLinkPre()
      p.sciHubLinkPost = this.sciHubLinkPost()
      this.resetTitle(p)
      return p
    },
    scihubDomain () {
      return getStorageItem('domain')
    }
  },
  watch: {
    'paper.cx' (val, oldVal) {
      if (val === oldVal + 1) {
        // this.loadPaperRest(() => {
        // }) has change to /paper/rest/map, load all result info
        // this.loadAltmetric()
        // this.getPdfUrl()
        setTimeout(() => {
          this.translatePaper()
        }, parseInt(Math.random() * 10000))
      }
    }
  },
  // created () {
  //   this.loadPaperRest(() => {
  //     this.loadAltmetric()
  //   })
  // },
  methods: {
    getCiteFormat (e) {
      this.winleft = e.clientX + 20
      this.wintop = e.clientY
      if (window.innerHeight - e.clientY < 400) {
        this.wintop = window.innerHeight - 400
      }

      this.$http.get(`${this.httpRoot}/paper/citation`, authenticate({params: {pmid: this.paper.id}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.citation = res.citation || ''
          this.showCite = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    getPdfUrl () {
      if (this.paper.fulltextLink) return
      // 解决后台并发线程阻塞的问题
      // changed 2022.6.12
      this.$http.get(`${this.httpRoot}/paper/fulltextlink`, authenticate({params: {pmid: this.paper.id, doi: this.paper.ids.doi}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paper.fulltextLink = res.url || ''
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onLinkClick (e) {
      if (!this.$parent.loggedIn) {
        e.preventDefault()
        updateStorageItem('route', {name: 'paperSearch', query: {'term': this.$parent.term}})
        this.$parent.showLoginTipWin2 = true
        return false
      } else {
        return true
      }
    },
    exportEndNote () {
      this.$http.post(`${this.httpRoot}/paper/endnote/export`, {
        pmids: [this.paper.id]
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'endnote.' + this.paper.id + '.nbib'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    exportNoteExpress () {
      this.$http.post(`${this.httpRoot}/paper/noteexpress/export`, {
        pmids: [this.paper.id]
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'noteexpress.' + this.paper.id + '.net'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onFulltextHelp (e) {
      if (!this.onLinkClick(e)) return
      this.$http.post(`${this.httpRoot}/paper/fulltext/add`, {
        title: this.paperCopy.a.t,
        pmid: this.paper.id,
        doi: this.paperCopy.ids.doi
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$parent.fulltextHelpSuccess()
        } else if (res.emailValid === 'null') {
          this.$parent.showEmailWin = true
        } else if (res.emailValid === 'false') {
          this.$parent.emailFailed(res.email)
        } else {
          this.$parent.fulltextHelpFailed(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    paperCollect () {
      if (!this.$parent.loggedIn) {
        updateStorageItem('route', {name: 'paperSearch', query: {'term': this.$parent.term}})
        this.$parent.showLoginTipWin = true
        return
      }
      this.collect()
      this.paper.status = 'collect'
      return false
    },
    paperUnCollect () {
      if (!this.$parent.loggedIn) {
        updateStorageItem('route', {name: 'paperSearch', query: {'term': this.$parent.term}})
        this.$parent.showLoginTipWin = true
        return
      }
      this.uncollect()
      this.paper.status = ''
      return false
    },
    paperRead () {
      if (!this.$parent.loggedIn) {
        updateStorageItem('route', {name: 'paperSearch', query: {'term': this.$parent.term}})
        this.$parent.showLoginTipWin = true
        return
      }
      this.read()
      this.paper.read = true
      return false
    },
    paperUnRead () {
      if (!this.$parent.loggedIn) {
        updateStorageItem('route', {name: 'paperSearch', query: {'term': this.$parent.term}})
        this.$parent.showLoginTipWin = true
        return
      }
      this.unread()
      this.paper.read = false
      return false
    },
    collect () {
      this.$http.post(`${this.httpRoot}/paper/collect`, {pmids: [this.paper.id]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          // callback()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    uncollect () {
      this.$http.post(`${this.httpRoot}/paper/uncollect`, {pmids: [this.paper.id]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          // callback()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    read () {
      this.$http.post(`${this.httpRoot}/paper/read`, {pmids: [this.paper.id]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          // callback()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    unread () {
      this.$http.post(`${this.httpRoot}/paper/unread`, {pmids: [this.paper.id]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          // callback()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleJournalMouseEnter (e) {
      let rect = {left: e.clientX, top: e.clientY}
      if (e.currentTarget) {
        let bdrect = e.currentTarget.getBoundingClientRect()
        rect = {left: bdrect.left + 40, top: bdrect.top - 5}
      }
      let journalFullTitle = ''
      if (this.paper.j && this.paper.j.t) {
        journalFullTitle = this.paper.j.t
        if (journalFullTitle.indexOf(':') > 0) {
          let ary = journalFullTitle.split(':')
          if (ary.length > 0) {
            journalFullTitle = ary[0].trim()
          }
        }
      }
      this.$emit('on-journal-mouseenter', this.paper.ifList, rect, journalFullTitle)
    },
    handleJournalMouseLeave () {
      this.$emit('on-journal-mouseleave')
    },
    onCopyTitle () {
      let input = document.createElement('input')
      input.setAttribute('value', this.paper.a.t)
      input.style.position = 'absolute'
      input.style.left = '-9999px'
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      this.$parent.showCopyTitleSuccess()
      setTimeout(() => {
        document.body.removeChild(input)
      }, 1000)
    },
    translatePaper () {
      // add 2022.6.15
      if (this.paper.chErr) {
        return
      }
      if (this.paper.a && this.paper.a.t && !this.paper.tCh) {
        this.translate2zh(this.paper.a.t, ch => {
          this.paper.tCh = ch
          this.updateTranslate(ch, 'title')
        })
      }
      if (this.paper.abstract && !this.paper.abCh && !this.paper.absCh) {
        this.translate2zh(this.paper.abstract, ch => {
          this.paper.abCh = ch
          this.updateTranslate(ch, 'abstract')
        })
      }
    },
    updateTranslate (text, type) {
      this.$http.post(`${this.httpRoot}/paper/translation/update`, {text, pmid: this.paper.id, type}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.ch) {
          //
        }
      })
      .catch(err => {
        console.log(err)
      })
    },
    sendTransError () {
      this.$http.post(`${this.httpRoot}/paper/translation/error`, {pmid: this.paper.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          //
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="less">
a.paper-summary-title {
  cursor: pointer;
  color: #0071bc;
  &:hover, &:active {
    color: #205493;
    outline: 0;
  }
  &:visited {
    color: #4c2c92;
  }
}
.copy-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.cite-num {
  color: #2c3e50;
  &.icon {
    margin-left: 10px;
  }
}
.ch-title {
  display: block;
  font-size: 0.875em;
  color: #584b4b;
}
.no-title {
  color: #262525;
  font-size: 1.375rem;
}
.journal-wrap {
  font-size: 0.875em;
  display: flex;
  flex-wrap: wrap;
  .journal, .if, .quality {
    margin: 0;
    padding: 0 5px;
    border: 2px solid #fff;
    flex: 0 0 auto;
  }
  .other {
    margin: 0;
    padding: 0 2px;
    border: 2px solid #fff;
    flex: 0 0 auto;
    &.date {
      color: #d82424;
    }
    &.review {
      color: #d82424;
    }
  }
  .if, .quality {
    background-color: #fff;
  }
  .if {
    margin-left: 2px;
  }
  .if, .quality {
    &.red {
      border-color: #ff2929;
    }
    &.yellow {
      border-color:#ff8a12;
    }
    &.blue {
      border-color:#008cde;
    }
    &.green {
      border-color:#00b561;
    }
    &.grey {
      border-color: grey;
    }
  }
  .if, .quality {
    &.red {
      background-color: #ff2929;
      color: #fff;
    }
    &.yellow {
      background-color:#ff8a12;
      color: #fff;
    }
    &.blue {
      background-color:#008cde;
      color: #fff;
    }
    &.green {
      background-color:#00b561;
      color: #fff;
    }
    &.grey {
      background-color: grey;
      color: #fff;
    }
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    color: rgb(46, 133, 64);
  }
}
.yxy-score {
  background: #ecd250;
  margin-right: 2px;
  span {
    margin: 0px 7px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
}
//
.journal-info {
  display: flex;
  align-items: center;
  line-height: 1.375rem;
  margin: 0.2rem 0;
}
.authors {
  .name {
    position: relative;
    margin-right: 4px;
    img {
      position: absolute;
      top: -3px;
      right: -0px;
      width: 12px;
      height: 12px;
      &.last {
        right: -10px;
      }
    }
    .number {
      position: absolute;
      top: -5px;
      right: -8px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background: #c4e1ff93;
      color: #1b70c9;
      font-size: 12px;
      text-align: center;
    }
  }
}
.paper.summary {
  display: none;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 3;  // 需要显示时文本行数
  // overflow: hidden;
}
.altmetric-icon {
  margin-left: 0.8rem;
  margin-right: 0.25rem;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url(../../assets/imgs/altmetric-logo.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.altmetric-score {
  color: #ff8a12;
}
.no-abstract {
  margin: 15px 0;
}
.abstractch {
  margin-top: 10px;
  position: relative;
  .ch-err {
    cursor: pointer;
    padding: 0 6px;
    background-color: #fcfcfc;
    color: #8a8a8a;
    font-size: 12px;
    // text-decoration: underline;
  }
}
.keywords {
  margin-top: 10px;
  .label {
    font-weight: 600;
  }
}
.full-text-link-panel {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  &> .label {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    margin-right: 5px;
  }
  .full-text-links-list {
    .text {
      display: none;
    }
  }
  .journal {
    &> img {
      height: 1.2rem;
      margin-right: 0.4rem;
      display: block;
    }
  }
  .pmc {
    display: block;
    width: 6rem;
    height: 1.2rem;
    line-height: 1.2rem;
    text-align: center;
    background: url(../../assets/imgs/pmc-full-text.png) no-repeat center/contain;;
  }
  .sci-hub {
    display: block;
    width: 6.0rem;
    height: 1.2rem;
    line-height: 1.2rem;
    text-align: center;
    background: url(../../assets/imgs/sci-hub.png) no-repeat center/contain;
  }
  .pdf {
    display: block;
    width: 2rem;
    height: 1.3rem;
    text-align: center;
    background: url(../../assets/imgs/pdf.png) no-repeat center/contain;
  }
  .weblink {
    display: block;
    width: 2rem;
    height: 1.3rem;
    text-align: center;
    background: url(../../assets/imgs/link.png) no-repeat center/contain;
  }
}
.tools-panel {
  cursor: pointer;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  &>span {
    &:hover {
      color: #216892;
    }
  }
  .text {
    margin-right: 0.5rem;
  }
}
.tools-icon {
  color: #584b4b;
  &.fa-heart {
    color:#ff6112;
  }
  &.read {
    padding: 0 7px;
    height: 14px;
    text-align: center;
    background: url(../../assets/imgs/read.png) no-repeat center/contain;
  }
  &.unread {
    padding: 0 7px;
    height: 14px;
    text-align: center;
    background: url(../../assets/imgs/readed.png) no-repeat center/contain;
  }
}
.fulltext-help {
  width: 5rem;
  height: 1rem;
  text-align: center;
  background: url(../../assets/imgs/help.png) no-repeat center/contain;
}
</style>
