import { authenticate, catchExpired } from '../../../utils/auth'
import { updateStorageItem, getStorageItem } from '../../../utils/cache'

export default {
  methods: {
    tokenRefresh () {
      this.$http.post(`${this.httpRoot}/auth/token/refresh`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (res.token) {
            updateStorageItem('token', res.token)
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    tokenValidate () {
      let token = getStorageItem('token')
      this.$http.get(`${this.httpRoot}/auth/token/valid`, authenticate({params: {token}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (!res.valid) {
            // logout
            this.logout()
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    logout () {
      this.$http.post(`${this.httpRoot}/auth/logout`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        // 请求发出后再退出
        this.$store.dispatch('logout')
        //
        if (res.status === 'success') {
          if (res.token) {
            updateStorageItem('token', res.token)
          }
          if (res.token) {
            this.$router.push({name: 'home'})
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
