<template>
  <div class="page-box search-options">
    <div class="inner-wrap">
      <div class="result-actions">
        <div class="result-tools">
          <button class="save-results"
            @mouseenter="handleSaveMouseEnter"
            @mouseleave="handleSaveMouseLeave">批量收藏</button>
          <ul class="save-results-panel" v-show="showSavePanel"
            @mouseenter="handleSavePanelMouseEnter"
            @mouseleave="handleSavePanelMouseLeave">
            <li @click="handleCollect">我的收藏</li>
            <li @click="handleGroupAdd(item)" v-for="item in groupList" :key="item.id">{{item.name}}</li>
          </ul>
          <button class="journal-if"
            @mouseenter="handleIFFilterMouseEnter"
            @mouseleave="handleIFFilterMouseLeave">影响因子：{{ifKey?ifKey:'所有'}}</button>
        </div>
      </div>

      <div class="result-display">
        <div class="format">
          <span class="label">显示：</span>
          <!-- <button class="format-option" :class="{selected: format==='summary'}" @click.stop="format='summary'">概略</button>
          <button class="format-option" :class="{selected: format==='abstract'}" @click.stop="format='abstract'">摘要</button> -->
          <div class="check-item" v-for="(item, index) in formatList" :key="index">
            <div class="au-radio">
              <input type="radio" :id="item.value" :value="item.value" v-model="format">
              <label class="radio-box" :for="item.value"></label>
            </div>
            <div class="label">{{item.title}}</div>
          </div>
          <div class="check-item">
            <div class="au-checkbox-inline">
              <input type="checkbox" id="_id_xx_ok_10_001" value="show" v-model="chAbstract">
              <label class="check-box" for="_id_xx_ok_10_001"></label>
              <span>摘要翻译</span>
            </div>
          </div>
          <div class="check-item">
            <div class="au-checkbox-inline">
              <input type="checkbox" id="_id_xx_ok_10_002" value="all" v-model="allAuthor">
              <label class="check-box" for="_id_xx_ok_10_002"></label>
              <span>全部作者</span>
            </div>
          </div>
        </div>
        <div class="sorting">
          <span class="label">排序：</span>
          <button class="sort-option" :class="{selected: sort==='relevance'}" @click.stop="sort='relevance'">按最佳匹配</button>
          <button class="sort-option" :class="{selected: sort===''}" @click.stop="sort=''">按最新时间</button>
        </div>
      </div>
      <transition name="fade">
        <div class="journal-filter-panel" v-show="showIFPanel"
          @mouseenter="handleIFPanelMouseEnter"
          @mouseleave="handleIFPanelMouseLeave">
          <div class="if-line1" v-if="ifSeg1 && ifSeg1.length">
            <span class="if" v-for="item in ifSeg1" :key="item.key" @click="handleIFSelect(item)" :class="{active: ifKey===item.key}">
              <span v-if="item.ifmin<3">{{item.ifmin}}&le;IF&lt;{{item.ifmax}}</span>
            </span>
          </div>
          <div class="if-line2">
            <span class="if" v-for="item in ifSeg2" :key="item.key" @click="handleIFSelect(item)" :class="{active: ifKey===item.key}">
              <span v-if="item.ifmin>=3 && item.ifmin<20">{{item.ifmin}}&le;IF&lt;{{item.ifmax}}</span>
              <span v-else>{{item.ifmin}}&le;IF</span>
            </span>
            <span class="if" :class="{active: ifKey===''}" @click="handleIFSelectAll">所有</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { getStorageItem, updateStorageItem } from '../../utils/cache'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  data () {
    return {
      sort: getStorageItem('sort') || '',
      format: getStorageItem('format') || 'abstract',
      chAbstract: (getStorageItem('chAbstract') === null || getStorageItem('chAbstract') === 'show') ? ['show'] : [],
      allAuthor: (getStorageItem('allAuthor') === null || getStorageItem('allAuthor') === 'all') ? ['all'] : [], // 默认所有
      formatList: [{value: 'summary', title: '概略'}, {value: 'abstract', title: '摘要'}],
      ifKey: '',
      ifSegs: [],
      ifBtnHover: false,
      ifPanelHover: false,
      showIFPanel: false,
      groupList: [],
      saveBtnHover: false,
      savePanelHover: false,
      showSavePanel: false
    }
  },
  computed: {
    ifSeg1 () {
      let re = []
      this.ifSegs.forEach(item => {
        if (item.ifmin < 3) re.push(item)
      })
      return re
    },
    ifSeg2 () {
      let re = []
      this.ifSegs.forEach(item => {
        if (item.ifmin >= 3) re.push(item)
      })
      return re
    }
  },
  watch: {
    // new pubmed Best match: '', most recent: 'date', publication date: 'pubdate', first author: 'fauth', journal: 'jour'
    sort (val) {
      if (val === 'relevance') {
        this.$emit('on-sort', 'relevance')
        updateStorageItem('sort', 'relevance')
      } else if (val === '') {
        this.$emit('on-sort', '')
        updateStorageItem('sort', '')
      }
    },
    format (val) {
      if (val === 'summary') {
        this.$emit('on-format', 'summary')
        updateStorageItem('format', 'summary')
      } else if (val === 'abstract') {
        this.$emit('on-format', 'abstract')
        updateStorageItem('format', 'abstract')
      }
    },
    chAbstract (val) {
      if (val && val.length) {
        this.$emit('on-chabstract', 'show')
      } else {
        this.$emit('on-chabstract', 'hide')
      }
    },
    allAuthor (val) {
      if (val && val.length) {
        this.$emit('on-allauthor', 'all')
      } else {
        this.$emit('on-allauthor', 'part')
      }
    }
  },
  mounted () {
    this.loadIFSegments()
    this.loadGroupList()
  },
  methods: {
    loadIFSegments () {
      this.$http.get(`${this.httpRoot}/journal/if/segments`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.ifSegs = res.segs || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadGroupList () {
      this.$http.get(`${this.httpRoot}/group/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.groupList = res.groupList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleIFFilterMouseEnter () {
      this.ifBtnHover = true
      this.showIFPanel = true
    },
    handleIFFilterMouseLeave () {
      this.ifBtnHover = false
      setTimeout(() => {
        if (!this.ifPanelHover && !this.ifBtnHover) {
          this.showIFPanel = false
        }
      }, 500)
    },
    handleIFPanelMouseEnter () {
      this.ifPanelHover = true
    },
    handleIFPanelMouseLeave () {
      this.ifPanelHover = false
      this.handleIFFilterMouseLeave()
      // this.showIFPanel = false
    },
    handleIFSelect (item) {
      this.ifKey = item.key
      this.$emit('on-if', this.ifKey)
    },
    handleIFSelectAll () {
      this.ifKey = ''
      this.$emit('on-if', this.ifKey)
    },
    clear () {
      this.ifKey = ''
    },
    handleSaveMouseEnter () {
      this.saveBtnHover = true
      this.showSavePanel = true
    },
    handleSaveMouseLeave () {
      this.saveBtnHover = false
      setTimeout(() => {
        if (!this.savePanelHover && !this.saveBtnHover) {
          this.showSavePanel = false
        }
      }, 500)
    },
    handleSavePanelMouseEnter () {
      this.savePanelHover = true
    },
    handleSavePanelMouseLeave () {
      this.savePanelHover = false
      this.handleSaveMouseLeave()
    },
    handleCollect () {
      this.$emit('on-collect')
      this.showSavePanel = false
    },
    handleGroupAdd (group) {
      this.$emit('on-group-add', group.id)
      this.showSavePanel = false
    }
  }
}
</script>
<style lang="less">
.search-options {
  display: flex;
  justify-content: flex-end;
  font-size: 0.875rem;
}
.inner-wrap {
  width: 90%;
  height: 3.5rem;
  line-height: 3.5rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.result-actions, .result-display {
  display: flex;
  .result-tools {
    position: relative;
    display: flex;
    align-items: center;
    button {
      border-right-width: 1px;
      margin-right: 1.5rem;
      outline: 0;
      &:active {
        border-color: #0664df;
        color: #00367c;
      }
    }
    .journal-if {
      &:hover {
        border-color: #548dd8;
        color: #00367c;
      }
    }
  }
  .save-results-panel {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    transform: translateY(45px);
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.45);
    &>li {
      list-style: none;
      padding: 0 10px;
      height: 28px;
      line-height: 28px;
      &:hover {
        background-color: #ddd;
        cursor: pointer;
      }
    }
  }
  .sorting, .format {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    .check-item {
      display:flex;
      margin-right: 0.5rem;
      &>.au-radio {
        display: flex;
        align-items: center;
        padding-right: 0.2rem;
      }
    }
  }
  .label {
    color: #5b616b;
    display: inline-block;
  }
  button {
    cursor: pointer;
    color: #212121;
    background-color: transparent;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    border: 1px solid #aeb0b5;
    transition: background-color 0.3s, border-color 0.3s,color 0.3s;
    padding: 0.3rem 0.8rem;
    border-right-width: 0;
    height: 2rem;
    &.selected {
      cursor: default;
      color: #fff;
      background-color: #0071bc;
      // border: 0;
      border-color: transparent;
    }
    &.sort-option, &.format-option {
      &:last-child {
        border-left-width: 0px;
        border-right-width: 1px;
      }
    }
  }
}
.journal-filter-panel {
  position: absolute;
  z-index: 11;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.45);
  margin: 0;
  padding: 5px 10px;
  top: 3.2rem;
  left: 6.5rem;
  &> .if-line1, &> .if-line2 {
    height: 40px;
    line-height: 40px;
    &> .if {
      cursor: pointer;
      padding: 3px 7px;
      margin: 0 4px;
      border: 1px solid #ccc;
      &:hover {
        border-color: #0071bc;
      }
      &.active {
        cursor: default;
        color: #fff;
        background-color: #0071bc;
        // border: 0;
        border-color: transparent;
      }
    }
  }
  // &> .if-line2 {
  //   padding-left: 74px;
  // }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 20px;
    width: 0.6rem;
    height: 0.6rem;
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.45);
    transform: translate(0, -50%) rotate(45deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 16px;
    width: 1.2rem;
    height: 0.5rem;
    background: #fff;
  }
}
@media screen and (max-width: 900px) {
  .result-actions>.result-tools>.journal-if {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .result-display>.format {
    display: none;
  }
}
</style>
