<template>
  <div class="paper-search">
    <div class="search-box">
      <search-form :sTerm="sTerm" @on-term="onTerm" @on-search="onSearch"></search-form>
    </div>
    <div class="search-option-box">
      <search-option ref="searchOption" @on-sort="onSort" @on-format="onFormat" @on-if="onIF"
        @on-chabstract="onChAbstract"
        @on-allauthor="onAllAuthor"
        @on-collect="onPaperCollect"
        @on-group-add="onPaperAdd"></search-option>
    </div>
    <div class="page-box search-panel">
      <div class="search-filter-box" v-if="paperList && paperList.length">
        <year-count ref="yearCount" :datas="yearCounts" @on-years-change="onYearsChange" @on-years-reset="onYearsReset"></year-count>
        <search-filter
          @on-simsearch="onSimsearch"
          @on-articleattr="onArticleattr"
          @on-pubt="onPubt"
          @on-species="onSpecies"
          @on-ds1="onDs1"
          @on-filter-clear="onFilterClear"
          @on-years-reset="onYearsResetinParent"></search-filter>
      </div>
      <div class="search-result-list">
        <div v-if="showResultNull">
          <div class="search-result-null">检索结果为空 !</div>
          <div v-html="nullHint"></div>
          <!-- <a class="search-spare" target="_blank" :href="'http://spare.pmop.cn/pubmed?term='+term">点击备用Pubmed检索</a> -->
        </div>
        <div class="search-num" v-if="sum > 0">
          <div class="au-checkbox-inline">
            <input type="checkbox" id="_id_xx_ko_01_002" value="selectall" v-model="selectAll">
            <label class="check-box" for="_id_xx_ko_01_002"></label>
          </div>
          <!-- <span>结果总数：</span><span>{{sum}}</span> -->
          <div class="page-sum" v-if="showPage">
            <pagination pageSizeChangeable :total="sum" :pageIndex="page" :pageSize="pageSize"
              @on-currentpage-change="onPageChange" @on-pagesize-change="onPageSizeChange"></pagination>
          </div>
          <span class="selected-num" v-if="selectedList.length">已选择：<span>{{selectedList.length}}</span></span>
        </div>
        <!-- <div class="page-title" v-if="page > 0">
          <span class="text-bage">第</span>
          <span class="text">{{ pageIndex + 1 }}</span>
          <span class="text-bage">页</span>
        </div> -->
        <div class="paper-list">
          <div class="paper-item" v-for="(item, index) in paperList" :key="item.id">
            <div class="au-checkbox">
              <input type="checkbox" :id="item.id" :value="item.id" v-model="selectedList">
              <label class="check-box" :for="item.id"></label>
              <div class="num-index">{{ pageIndex*pageSize+index+1 }}</div>
            </div>
            <div class="paper-wrap">
              <paper-item-one :paper="item" :format="format" :term="term" :chAbstract="chAbstract" :allAuthor="allAuthor" :translation="translation"
                @on-journal-mouseenter="onJournalMouseEnter"
                @on-journal-mouseleave="onJournalMouseLeave"></paper-item-one>
            </div>
          </div>
        </div>
        <div class="paper-pagination" v-if="sum > 0">
          <pagination pageSizeChangeable :total="sum" :pageIndex="page" :pageSize="pageSize" placement="top"
            @on-currentpage-change="onPageChange" @on-pagesize-change="onPageSizeChange"></pagination>
        </div>
      </div>
    </div>
    <transition name="fade">
      <loading v-if="showLoading"></loading>
    </transition>
    <login-tip-win :show="showLoginTipWin" @on-hide="showLoginTipWin=false"></login-tip-win>
    <login-tip-win2 :show="showLoginTipWin2" @on-hide="showLoginTipWin2=false"></login-tip-win2>
    <tooltip-win :show="showSaveResultWin" @on-hide="showSaveResultWin=false" :autoClose="autoClose" :tipType="tipType" :duration="duration" :text="saveResultText"></tooltip-win>
    <confirm-win :show="showConfirmWin" @on-hide="showConfirmWin=false" :title="confirmTitle" :text="confirmText" okText="重新提交" @on-ok="onConfirmOk"></confirm-win>
    <email-win :show="showEmailWin" @on-hide="showEmailWin=false" @on-ok="onEmail"></email-win>
    <div class="if-chart" v-show="showIfList" :style="chartPos">
      <div class="chart-box">
        <y-line :width="500" :height="200" :data="ifList" :chartTitle="chartTitle" :options="optionsIfList"></y-line>
      </div>
    </div>
    <div class="j-full-title" v-show="showJFullTitle" :style="chartPos">
      <div class="j-full-title-box">{{chartTitle}}</div>
    </div>
  </div>
</template>
<script>
import YearCount from './year-count'
import SearchFilter from './search-filter'
import SearchForm from './search-form'
import SearchOption from './search-option'
import PaperItemOne from './paper-item-one'
import EmailWin from './email-win'
import LoginTipWin from '../login-tip-win/login-tip-win'
import LoginTipWin2 from '../login-tip-win/login-tip-win2'
import TooltipWin from '../login-tip-win/tooltip-win'
import ConfirmWin from '../login-tip-win/confirm-win'
import Pagination from '../common/pagination/pagination'
import Loading from '../common/loading/loading'
import paperCache from './mixins/paperCache'
import esearch from './mixins/esearchresult'
import esearch2 from './mixins/esearchresult2'
import ChartMixin from './mixins/chart'
import loadpmids from './mixins/loadpmids'
import tokenRefresh from './mixins/tokenrefresh'
import YLine from '../common/charts/line'
import Login from '../../utils/login'
import { authenticate, catchExpired } from '../../utils/auth'
import { getStorageItem, updateStorageItem } from '../../utils/cache'

export default {
  name: 'paper-search',
  components: { YearCount, SearchFilter, SearchForm, SearchOption, PaperItemOne, LoginTipWin, LoginTipWin2, TooltipWin, ConfirmWin, EmailWin, Pagination, Loading, YLine },
  mixins: [paperCache, ChartMixin, esearch, esearch2, loadpmids, tokenRefresh, Login],
  data () {
    return {
      sTerm: '',
      pageSize: Number(getStorageItem('pageSize')) || 20,
      pageIndex: 0,
      paperList: [],
      yearCounts: {},
      selectedList: [],
      selectAll: [],
      showLoading: false,

      showPage: false,
      showResultNull: false,
      nullHint: '',

      sum: 0,

      translation: '',
      page: 0,  // pageIndex, 下标 0 开始
      term: '',
      sort: getStorageItem('sort') || '',
      format: getStorageItem('format') || '',
      chAbstract: getStorageItem('chAbstract') || 'show',
      allAuthor: getStorageItem('allAuthor') || 'all',
      ifKey: '',

      simsearch: [],
      articleattr: [],
      pubt: [],
      species: [],
      ds1: '',
      years: '',

      retstart: 0,  // 再查询条件变更后重置、loadPmids后更新
      retmax: 0, // 传参后台设置，最大length
      idlist: {},
      webenv: '',
      querykey: '1',

      showLoginTipWin: false,
      showLoginTipWin2: false,
      showSaveResultWin: false,
      duration: 1500,
      tipType: 'success',
      saveResultText: '收藏成功，请到在线文献管理查看！',
      autoClose: true,

      showEmailWin: false,
      showConfirmWin: false,
      confirmTitle: '您的邮箱地址尚未验证通过！',
      confirmText: '',

      pageIndexChange: 0 // 存在两个分页条，防止切换页面请求两次
    }
  },
  watch: {
    selectAll (val) {
      if (val && val.length) {
        this.selectedList = this.paperList.map(item => item.id)
      } else {
        this.selectedList = []
      }
    },
    selectedList (val) {
      console.log(val)
    }
  },
  computed: {
    loggedIn () {
      let auth = getStorageItem('auth')
      let loggedAuth = this.$store.state.auth.loggedAuth
      let isLogin = this.$store.getters.getLoginState
      return auth && loggedAuth && isLogin
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.term) {
      next(vm => {
        vm.term = to.query.term
        vm.loadPaperList()
        vm.sTerm = vm.term
      })
    } else if (to.query && to.query.pmcCites) {
      next(vm => {
        let pmids = to.query.pmcCites.split(',')
        pmids = pmids.map(item => Number(item))
        vm.loadPapers(pmids, true)
      })
    } else {
      next(vm => {
        vm.loadPaperListFromCache(() => {
          vm.sTerm = vm.term
        })
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.cachePaperList(this.term, this.sum, this.paperList, this.page, this.yearCounts, this.selectedList, this.idlist, this.showPage)
    next()
  },
  mounted () {
    document.title = 'PubMed Optimizer'
    let token = getStorageItem('token')
    if (token) {
      this.tokenValidate()
    } else {
      this.tokenRefresh()
    }
  },
  methods: {
    onTerm (term) {
      this.term = term
    },
    onSearch (term) {
      this.term = term
      this.page = 0
      this.years = ''
      this.ifKey = ''
      this.$refs.searchOption.clear()
      this.retstart = 0
      this.idlist = {}
      if (this.$nodeEnv === 'production_test') {
        if (this.term.indexOf(',') > 0) {
          let pmids = this.term.split(',')
          pmids = pmids.map(item => Number(item))
          this.loadPapers(pmids)
        }
      } else {
        this.loadPaperList()
      }
    },
    onSort (sort) {
      this.sort = sort
      this.page = 0
      this.retstart = 0
      this.idlist = {}
      this.loadPaperList()
    },
    onFormat (format) {
      this.format = format
      // this.page = 0
      // this.retstart = 0
      // this.idlist = []
      // this.loadPaperList()
    },
    onChAbstract (status) {
      this.chAbstract = status
      updateStorageItem('chAbstract', status)
    },
    onAllAuthor (status) {
      this.allAuthor = status
      updateStorageItem('allAuthor', status)
    },
    onIF (ifKey) {
      this.ifKey = ifKey
      this.page = 0
      this.retstart = 0
      this.idlist = {}
      this.loadPaperList()
    },
    onSimsearch (simsearch) {
      this.simsearch = simsearch
      this.page = 0
      this.retstart = 0
      this.idlist = {}
      this.loadPaperList()
    },
    onArticleattr (articleattr) {
      this.articleattr = articleattr
      this.page = 0
      this.retstart = 0
      this.idlist = {}
      this.loadPaperList()
    },
    onPubt (pubt) {
      this.pubt = pubt
      this.page = 0
      this.retstart = 0
      this.idlist = {}
      this.loadPaperList()
    },
    onSpecies (species) {
      this.species = species
      this.page = 0
      this.retstart = 0
      this.idlist = {}
      this.loadPaperList()
    },
    onDs1 (ds1) {
      this.ds1 = ds1
      this.page = 0
      this.retstart = 0
      this.idlist = {}
      this.loadPaperList()
    },
    onYearsChange (startYear, endYear) {
      this.years = startYear + '-' + endYear
      this.page = 0
      this.retstart = 0
      this.idlist = {}
      this.loadPaperList(() => {
        this.$nextTick(() => {
          this.$refs.yearCount.filterYearRange(startYear, endYear)
        })
      })
    },
    onYearsReset () {
      this.years = ''
    },
    onYearsResetinParent () {
      this.$refs.yearCount.handleReset()
    },
    onFilterClear () {
      this.simsearch = []
      this.articleattr = []
      this.pubt = []
      this.species = []
      this.ds1 = ''
      this.years = ''
      this.startYear = ''
      this.endYear = ''
      this.ifKey = ''
      this.$refs.searchOption.clear()
      this.retstart = 0
      this.idlist = {}
      this.loadPaperList()
    },
    onPageChange (pageNum) {
      this.page = pageNum - 1
      this.$el.scrollIntoView()
      //
      this.pageIndexChange += 1
      if (this.pageIndexChange === 1) {
        if (this.$nodeEnv === 'production_test') {
          this.handlePaginationChangeTest()
        } else {
          this.handlePaginationChange()
        }
      }
    },
    onPageSizeChange (size) {
      updateStorageItem('pageSize', size)
      this.page = 0
      this.pageIndex = 0
      this.pageSize = size
      this.handlePaginationChange()
    },
    handlePaginationChange () {
      // page, pageSize 发生变化
      setTimeout(() => {
        this.pageIndexChange = 0
      }, 500)
      let pmids = this.getPmids(this.page, this.pageSize)
      if (pmids.length) {
        // 加载 paperList 关闭 loading 状态, 并更新pageIndex
        this.loadPapers(pmids)
      } else {
        // 没取到pmids, 需要取下一批 idlist (前面取过的不需要取)
        // 根据 page 更新 retstart
        this.retstart = this.getRetstart(this.page, this.pageSize)
        this.loadPaperList()
      }
    },
    loadPaperList (callback) {
      if (this.$nodeEnv === 'production_test') {
        this.loadPaperListTest()
        return
      }
      if (!this.term) return
      this.showLoading = true

      this.$http.post(`${this.httpRoot}/paper/search/pmids`, this.getParam2(), authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.result) {
          //
          this.translation = this.getTranslation(res.result.translationstack || [])
          // 更新一批idlist
          this.sum = Number(res.result.count)
          this.retmax = Number(res.result.retmax)
          this.retstart = Number(res.result.retstart)
          this.idlist[this.retstart] = res.result.idlist || []
          this.webenv = res.result.webenv || ''
          this.querykey = res.result.querykey || '1'

          if (Number(res.result.count) === 0 || !res.result.idlist.length) {
            this.showLoading = false
            this.paperList = []
            this.showResultNull = true
            if (res.result.warninglist && res.result.warninglist.outputmessages) {
              this.nullHint = res.result.warninglist.outputmessages.toString()
            } else {
              this.nullHint = ''
            }
          } else {
            this.showResultNull = false
            // 从 idlist 中取当前页 20 个（注意不一定是第一页）
            let pmids = this.getPmids(this.page, this.pageSize)
            // 加载 paperList 关闭 loading 状态, 并更新pageIndex
            this.loadPapers(pmids)
            if (typeof callback === 'function') {
              callback()
            }
          }
        } else {
          this.showLoading = false
          this.nullHint = res.statusText
          this.paperList = []
          this.showResultNull = true
        }
      })
      .catch(err => {
        this.showLoading = false
        this.nullHint = ''
        this.paperList = []
        this.showResultNull = true
        catchExpired(err, this)
      })
      // loadYearCounts
      if (this.page === 0 && !this.years) {
        this.loadYearCounts()
      }
    },
    loadYearCounts () {
      this.$http.post(`${this.httpRoot}/paper/search/yearcounts`, this.getParam(), authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.yearCounts) {
          this.yearCounts = res.yearCounts || {}
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    getRestList (pmids, callback) {
      this.$http.post(`${this.httpRoot}/paper/rest/map`, {pmids}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.restMap || {})
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    getScoreList (altlist, callback) {
      this.$http.post(`${this.httpRoot}/paper/score/map`, {altlist}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.pmidScores || {})
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    getFulltextList (idlist, callback) {
      this.$http.post(`${this.httpRoot}/paper/fulltextlink/map`, {idlist}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.pmidFulltextlinks || {})
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    getCiteList (pmids, callback) {
      this.$http.post(`${this.httpRoot}/paper/cite/pmc`, {pmids}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.pmcCites || {})
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    getParam () {
      let param = {}
      param.page = this.page + 1
      param.term = this.term
      param.sort = this.sort === 'relevance' ? '' : this.sort === '' ? 'date' : ''
      param.format = '' // this.format
      param.ifKey = this.ifKey
      param.simsearch = this.simsearch
      param.articleattr = this.articleattr
      param.pubt = this.pubt
      param.species = this.species
      param.ds1 = this.ds1
      param.years = this.years
      return param
    },
    getParam2 () {
      let param = {}
      // param.page = this.page + 1
      param.term = this.term
      param.sort = this.sort
      param.ifKey = this.ifKey
      param.ptyps = this.mapPtypesPubts(this.simsearch)
      param.pubts = this.mapPtypesPubts(this.pubt)
      param.species = this.mapPtypesPubts(this.species)
      let years = this.years.split('-')
      if (years.length === 2) {
        param.startPubDate = years[0]
        param.endPubDate = years[1]
      }
      param.retstart = String(this.retstart)
      param.webenv = this.webenv
      param.querykey = this.querykey
      return param
    },
    mapPtypesPubts (array) {
      return array.map(str => {
        if (str === 'fha') {
          return 0
        } else if (str === 'ffrft') {
          return 1
        } else if (str === 'fft') {
          return 2
        } else if (str === 'review') {
          return 0
        } else if (str === 'clinicaltrial') {
          return 1
        } else if (str === 'systematicreviews') {
          return 2
        } else if (str === 'randomizedcontrolledtrial') {
          return 3
        } else if (str === 'booksdocs') {
          return 4
        } else if (str === 'metaanalysis') {
          return 5
        } else if (str === 'casereport') {
          return 6
        } else if (str === 'humans') {
          return 0
        } else if (str === 'animals') {
          return 1
        }
      })
    },
    loadPapers (pmids, getPmcCites) {
      if (!pmids || !pmids.length) {
        this.showLoading = false
        return
      }
      pmids = pmids.map(id => Number(id))
      this.showLoading = true

      this.$http.post(`${this.httpRoot}/paper/list`, {pmids}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          let pmidIssnList = []
          res.paperList.forEach(p => {
            pmidIssnList.push({pmid: p.id, issn: p.j ? p.j.issn || '' : ''})
            p.cx = 1 // 便于触发修改
          })
          this.paperList = res.paperList || []
          if (getPmcCites) {
            this.sum = pmids.length
          } else {
            this.pageIndex = this.page
            this.showPage = true
          }
          //
          const idlist = this.paperList.map(item => {
            return {
              pmid: item.id,
              doi: item.ids && item.ids.doi
            }
          })
          // get all paper rest info
          this.getRestList(pmids, restMap => {
            this.paperList.forEach(paper => {
              if (restMap[paper.id]) {
                let paperRest = restMap[paper.id]
                paper.pmcCites = paperRest.pmcCites || []
                paper.citedby = paperRest.citedby || 0
                paper.alt = paperRest.alt || 0
                paper.altId = paperRest.altId || ''
                paper.score = paperRest.score || 0
                paper.tCh = paperRest.tCh || ''
                paper.abCh = paperRest.abCh || ''
                paper.chErr = paperRest.chErr
                // 触发修改
                paper.cx += 1
              }
            })
            // get all paper score
            const altlist = this.paperList.map(item => {
              return {
                pmid: item.id,
                alt: item.alt,
                altId: item.altId
              }
            })
            this.getScoreList(altlist, scoreMap => {
              this.paperList.forEach(paper => {
                if (scoreMap[paper.id]) {
                  let paperScore = scoreMap[paper.id]
                  paper.score = paperScore
                }
              })
            })
          })
          // get all paper fulltextlink
          this.getFulltextList(idlist, linkMap => {
            this.paperList.forEach(paper => {
              if (linkMap[paper.id]) {
                let fulltextLink = linkMap[paper.id]
                paper.fulltextLink = fulltextLink
              }
            })
          })
          // get all paper pmcCite and update
          this.getCiteList(pmids, pmcCites => {
            this.paperList.forEach(paper => {
              if (pmcCites[paper.id]) {
                paper.pmcCites = pmcCites[paper.id] || []
              }
            })
          })
          // get call paper if list
          this.getIfList(pmidIssnList, iflistMap => {
            this.paperList.forEach(paper => {
              if (iflistMap[paper.id] && iflistMap[paper.id].length) {
                paper.ifList = iflistMap[paper.id] || []
              }
            })
          })
          this.showLoading = false
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
    },
    onPaperCollect () {
      if (!this.selectedList.length) return
      this.$http.post(`${this.httpRoot}/paper/collect`, {pmids: this.selectedList}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.autoClose = true
          this.tipType = 'success'
          this.duration = 1500
          this.saveResultText = '收藏成功，请到在线文献管理查看！'
          this.showSaveResultWin = true
        } else {
          updateStorageItem('route', {name: 'paperSearch', query: {'term': this.term}})
          this.showLoginTipWin = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onPaperAdd (groupId) {
      if (!this.selectedList.length) return
      this.$http.post(`${this.httpRoot}/group/paper/add`, {id: groupId, pmids: this.selectedList}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.autoClose = true
          this.tipType = 'success'
          this.duration = 1500
          this.saveResultText = '收藏成功，请到在线文献管理查看！'
          this.showSaveResultWin = true
        } else {
          updateStorageItem('route', {name: 'paperSearch', query: {'term': this.term}})
          this.showLoginTipWin = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    showCopyTitleSuccess () {
      this.autoClose = true
      this.tipType = 'success'
      this.duration = 1000
      this.saveResultText = '标题复制成功！'
      this.showSaveResultWin = true
    },
    fulltextHelpSuccess () {
      this.autoClose = true
      this.tipType = 'success'
      this.duration = 1500
      this.saveResultText = '求助文献已进入文献互助，等待他人应助'
      this.showSaveResultWin = true
    },
    fulltextHelpFailed (text) {
      this.autoClose = false
      this.tipType = 'warning'
      this.duration = 2000
      this.saveResultText = text || '求助文献失败'
      this.showSaveResultWin = true
    },
    emailFailed (email) {
      this.confirmText = '<div class="tip01">' +
                            '<div>原因可能如下：1.填写的邮箱地址' + email + '不正确；2.未在您的邮箱中点击验证邮件；3.您的邮箱未收到验证邮件。</div>' +
                            '<div class="tipfoot01">您也可以发送您的微信昵称至管理员邮箱(service100@iyxy.cn)手动完成邮箱验证。</div>' +
                          '</div>'
      this.showConfirmWin = true
    },
    onConfirmOk () {
      this.showEmailWin = true
    },
    onEmail (email) {
      this.$http.post(`${this.httpRoot}/auth/email/update`, {email: email}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.autoClose = false
          this.tipType = 'success'
          this.duration = 1500
          this.saveResultText = '邮箱已设置，确认邮件已发送到您的邮箱，请查看邮件进行确认'
          this.showSaveResultWin = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  },
  errorCaptured (err, vm, info) {
    console.log('paper-search', err, vm, info)
  }
}
</script>
<style lang="less">
.paper-search {
  font-size: 0.875rem;
}
.search-box {
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
}
.search-option-box {
  border-bottom: 1px solid #ccc;
}
.search-panel {
  position: relative;
}
.search-filter-box {
  width: 16%;
  position: absolute;
  left: -6%;
  top: 0;
}
.search-result-null {
  height: 80px;
  line-height: 80px;
  color: #ff4545;
}
.search-spare {
  text-decoration: underline;
}
.search-result-list {
  width: 90%;
  margin-left: 10%;
}
.search-num {
  height: 65px;
  display: flex;
  align-items: center;
}
.selected-num {
  margin-left: 30px;
}
.paper-item {
  margin-bottom: 1rem;
  position: relative;
  &> .au-checkbox {
    position: absolute;
    &> .check-box {
      &:hover +.tooltip {
        display: block;
      }
    }
    &> .tooltip {
      position: absolute;
      display: none;
      padding: 3px 7px;
      border-radius: 2px;
      font-size: 12px;
      white-space: nowrap;
      background: rgba(0, 0, 0, 0.65);
      color: #fff;
      top: 0;
      left: 0;
      transform: translate(-105%, 0);
      &:before {
        position: absolute;
        content: '';
        display: block;
        top: 50%;
        right: 0;
        width: 0;
        height: 0;
        border-top: 2px solid rgba(0, 0, 0, 0.65);
        border-right: 2px solid rgba(0, 0, 0, 0.65);
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }
  }
}
.paper-wrap {
  display:inline-block;
  margin-left: 2.25rem;
}
.paper-pagination {
  height: 60px;
}
.page-title {
  position: relative;
  padding: 0;
  margin-bottom: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #323a45;
  display: flex;
  justify-content: center;
  &:before {
    content: ' ';
    display: block;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    background-color: #d6d7d9;
  }
  .text-bage {
    display: inline-block;
    padding: 0 0.5rem;
    position: relative;
    background-color: #fff;
  }
  .text {
    display: inline-block;
    position: relative;
    background-color: #fff;
  }
}
a.highlight {
  color: #458fff;
  &.title {
    font-weight: 600;
    color: #0037ff;
  }
}
@media screen and (max-width: 800px) {
  .search-panel>.search-filter-box {
    display: none;
  }
}
</style>
